
import { defineComponent, computed, watchEffect } from 'vue';
import { ElCarousel } from 'element-plus';
import { ElCarouselItem } from 'element-plus';
import { ElImage } from 'element-plus';
import Products from '../components/Products.vue';
import { GET_SLIDES, SHOW_OPTIONS, SHOW_PRODUCTS } from '@/store/type-actions';
import { useStore } from '@/store';

export default defineComponent({
  name: 'ShopHome',
  components: {
    ElCarousel,
    ElCarouselItem,
    Products,
    ElImage,
  },

  data() {
    return {
      env: process.env.VUE_APP_API_URL,
      slide: [{ imagem: '' }],
    };
  },

  mounted() {
    watchEffect(() => {
      if (JSON.parse(JSON.stringify(this.slides)).length !== 0) {
        this.slide = JSON.parse(JSON.stringify(this.slides));
      }
    });
  },

  setup() {
    const store = useStore();
    store.dispatch(SHOW_PRODUCTS);
    store.dispatch(SHOW_OPTIONS);
    store.dispatch(GET_SLIDES);
    return {
      store,
      product: computed(() => store.state.products),
      option: computed(() => store.state.productOptions),
      slides: computed(() => store.state.slides),
    };
  },
});
