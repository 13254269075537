
import { computed, defineComponent, watchEffect, } from "vue";
import QrcodeVue from "qrcode.vue";
import { useStore } from "@/store";
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: "modalPayment",
  components: {
    QrcodeVue,
    Icon,
  },

  props: ['regenerate', 'restartingQR'],

  data() {
    return {
      blur: false
    }
  },

  mounted() {
    watchEffect(() => {
      if (this.pix.timer == -1) {
        this.blur = true
      } else {
        this.blur = false
      }
    })
  },

  setup() {
    const store = useStore()
    const pix = computed(() => store.state.pixQrCode)

    return {
      pix,
    };
  }
});
