
import { defineComponent } from 'vue';
import Header from './components/Header.vue';
import Notification from './components/Notification.vue';
import { useStore } from '@/store';
import { GET_USERS, REGISTER_VO } from '@/store/type-actions';

export default defineComponent({
  name: 'appEnerzee',
  components: {
    Header,
    Notification,
  },

  data() {
    return {
      load: true,
    };
  },

  async mounted() {
    if (
      localStorage.getItem('login') !== '' &&
      localStorage.getItem('login') !== null
    ) {
      try {
        await this.store.dispatch(GET_USERS, localStorage.getItem('login'));
      } catch (err: unknown) {
        err;
      }

      try {
        await this.store
          .dispatch(REGISTER_VO, localStorage.getItem('login'))
          .finally(() => (this.load = false));
      } catch (err: unknown) {
        err;
      }
    } else if (
      localStorage.getItem('login') == '' ||
      (localStorage.getItem('login') == null && this.$route.name !== 'auth')
    ) {
      localStorage.setItem('login', '');
      this.load = false;
    }
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },
});
